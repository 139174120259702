import './App.css'
import React, { useMemo } from 'react'

const colors = [
  {
    name: 'Carbon',
    code: '#333',
  },
  {
    name: 'Carbon Blue',
    code: '#373c4f',
  },
  {
    name: 'Carbon Copy',
    code: '#545554',
  },
  {
    name: 'Carbon Fiber',
    code: '#2e2e2e',
  },
  {
    name: 'Carbon Footprint',
    code: '#7b808b',
  },
  {
    name: 'African Bubinga',
    code: '#c7927a',
  },
  {
    name: 'African Dust',
    code: '#c5baaa',
  },
  {
    name: 'African Gray',
    code: '#939899',
  },
  {
    name: 'African Mahogany',
    code: '#cd4a4a',
  },
  {
    name: 'African Mud #1',
    code: '#696969',
  },
  {
    name: 'African Mud #2',
    code: '#826c68',
  },
  {
    name: 'African Plain',
    code: '#86714a',
  },
  {
    name: 'African Queen',
    code: '#645e42',
  },
  {
    name: 'African Safari',
    code: '#b16b40',
  },
  {
    name: 'African Sand',
    code: '#ccaa88',
  },
  {
    name: 'African Sunset',
    code: '#ee7828',
  },
  {
    name: 'African Violet',
    code: '#b085b7',
  },
  {
    name: 'Mama Africa',
    code: '#551111',
  },
]

const Symbol = ({ color1, color2, color3 }) => {
  return (
    <svg
      height="64"
      viewBox="0 0 56 64"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="m28 0 27.7128129 16v32l-27.7128129 16-27.71281292-16v-32z"
          fill={color2}
        />
        <path
          d="m55.7128129 16v32l-27.7128129 16-27.71281292-16z"
          fill={color3}
        />
        <path d="m28 0 27.7128129 16v32l-55.42562582-32z" fill={color1} />
      </g>
    </svg>
  )
}

function App() {
  const remixes = useMemo(() => {
    const experiments = []

    // Dla każdego koloru carbonu
    //   W każdym z trzech miejsc
    //     Wygeneruj wersję z każdą możliwą parą kolorów afrykańskich
    //       W dwóch różnych możliwych miejscach

    // Dla każdego koloru carbonu

    colors
      .filter(color => color.name.includes('Carbon'))
      .forEach(color => {
        // W każdym z trzech miejsc

        ;[1, 2, 3].forEach(position => {
          const availablePositions = [1, 2, 3]

          const currentPositionIndex = availablePositions.indexOf(position)

          availablePositions.splice(currentPositionIndex, 1)

          colors
            .filter(color => color.name.includes('Africa'))
            .forEach(secondColor => {
              colors
                .filter(color => color.name.includes('Africa'))
                .filter(color => color.name !== secondColor.name)
                .forEach(thirdColor => {
                  const experiment = []

                  experiment[position - 1] = color.code
                  experiment[availablePositions[0] - 1] = secondColor.code
                  experiment[availablePositions[1] - 1] = thirdColor.code

                  const reverseExperiment = []

                  reverseExperiment[position - 1] = color.code
                  reverseExperiment[availablePositions[0] - 1] = thirdColor.code
                  reverseExperiment[availablePositions[1] - 1] =
                    secondColor.code

                  const experimentIndex = experiments.findIndex(
                    e => JSON.stringify(e) === JSON.stringify(experiment)
                  )

                  if (experimentIndex === -1) {
                    experiments.push(experiment)
                  }

                  const reverseExperimentIndex = experiments.findIndex(
                    e => JSON.stringify(e) === JSON.stringify(reverseExperiment)
                  )

                  if (reverseExperimentIndex === -1) {
                    experiments.push(reverseExperiment)
                  }
                })
            })
        })
      })

    return experiments
  }, [])

  console.log(remixes)

  return (
    <div>
      <h1>54carbon Color Exploration</h1>
      <h2>Colors</h2>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: '3rem' }}>
          <h3>Africa</h3>
          <ul>
            {colors
              .filter(color => color.name.includes('Africa'))
              .map((color, index) => (
                <li key={color.code}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '1rem',
                      backgroundColor: color.code,
                      marginRight: '0.5rem',
                    }}
                  >
                    &nbsp;
                  </span>
                  {index + 1}. {color.name}
                </li>
              ))}
          </ul>
        </div>
        <div>
          <h3>Carbon</h3>
          <ul>
            {colors
              .filter(color => color.name.includes('Carbon'))
              .map((color, index) => (
                <li key={color.code}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '1rem',
                      backgroundColor: color.code,
                      marginRight: '0.5rem',
                    }}
                  >
                    &nbsp;
                  </span>
                  {index + 1}. {color.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <h2>Remixes</h2>
      <p>
        I know the choice is paralyzing, but maybe something will catch your eye
        or inspire you :)
      </p>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: '-2rem',
          marginRight: '-2rem',
          paddingTop: '1rem',
        }}
      >
        {remixes.map((remix, index) => (
          <div
            key={index}
            style={{ paddingRight: '2rem', paddingBottom: '2rem' }}
          >
            <div>
              <Symbol color1={remix[0]} color2={remix[1]} color3={remix[2]} />
            </div>
            <div
              style={{
                fontSize: '11px',
                color: '#767676',
                textAlign: 'center',
              }}
            >
              {index + 1}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default App
